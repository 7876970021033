import React, { useEffect, useState } from 'react';
import { Navigate, Routes, Route, useParams } from 'react-router-dom';
import dateFormat from 'dateformat';

import { ajax, padNum, parseDate } from 'svs-utils/web';
import { createAlert, createDialog } from 'svs-utils/react';

import { useStateSlice } from '../utils/reactUtils.js';
import Tree from './tree.js';

import keyImage from '../images/key.png';

function AppRouter() {

    var componentProps = {};

    return (
        <Routes>
            <Route path='/'>
                <Route path=':homePath' element={<TreeLayout {...componentProps} />}>
                    <Route path=':homePath2' element={<TreeLayout {...componentProps} />}>
                        <Route path=':homePath3' element={<TreeLayout {...componentProps} />}></Route>
                    </Route>
                </Route>
                <Route path='404' element={<div>404</div>} />
                <Route index element={<Navigate to='/85/gagovieirafamily/85' />} />
            </Route>
        </Routes>
    );
}

function TreeLayout(props) {
    var [treeSlice, setTreeSlice] = useStateSlice('tree');

    var urlParams = useParams();

    // var user = urlParams.homePath || 85;
    var treeName = urlParams.homePath2 || 'gagovieirafamily';
    // var currentUser = urlParams.homePath3 || user;

    useEffect(() => {
        ajax({ endPoint: '/downloadTree', data: { tree: treeName } }).then((results) => {
            if (results.result) {
                var newPeople = {};
                Object.keys(results.data).forEach((id) => {
                    var person = results.data[id];
                    newPeople[id] = {
                        ...person,
                        birthday: person.birthday === '00 / 00 / 0000' ? null : new Date(person.birthday.replace(/\s/g, '')),
                        death: person.death && new Date(person.death.replace(/\s/g, '')),
                        // display: false,
                        name: `${person.firstname} ${person.lastname}`,
                        gender: person.sex === 'M' ? 'boy' : 'girl',
                        children: person.children ? person.children.split(',').map((id) => parseInt(id)) : [],
                        siblings: person.siblings ? person.siblings.split(',').map((id) => parseInt(id)) : [],
                        exes: person.exes ? person.exes.split(',').map((id) => parseInt(id)) : [],
                        type: 'person',
                        // top: 0,
                        // left: 0,
                    };
                });

                var sortFunc = (a, b) => {
                    if (newPeople[a].birthday < newPeople[b].birthday) {
                        return -1;
                    } else if (newPeople[a].birthday > newPeople[b].birthday) {
                        return 1;
                    } else {
                        return newPeople[a].name.localeCompare(newPeople[b].name);
                    }
                };
                for (var person of Object.values(newPeople)) {
                    if (person.siblings.length > 1) {
                        person.siblings.sort(sortFunc);
                    }
                    if (person.children.length > 1) {
                        person.children.sort(sortFunc);
                    }
                    if (person.exes.length > 1) {
                        person.exes.sort((a, b) => {
                            var maxAChild = Math.max(...newPeople[a].children.map((c) => newPeople[c].birthday.getTime()));
                            var maxBChild = Math.max(...newPeople[b].children.map((c) => newPeople[c].birthday.getTime()));
                            return maxAChild - maxBChild;
                        }).reverse();
                        // });
                    }
                }

                setTreeSlice({ people: newPeople });
            } else {
                console.log('downloadtree', results);
            }
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    var getPeople = async (ids) => {
        var results = await ajax({ endPoint: '/getPeople', params: { ids } });

        if (results.result) {
            var newPeople = { ...treeSlice.people };
            var data = results.data;
            for (var i in data) {
                if (parseInt(i) > 0) {
                    newPeople[i.toString()] = data[i];
                }
            }
            setTreeSlice({ people: newPeople });
        } else {
            createAlert("getpeople " + JSON.stringify(results));
        }
    };

    return (
        Object.keys(treeSlice.people).length ? (
            <React.Fragment>
                <div id="editbutton" onClick={() => showEditBox(1, treeSlice.people, getPeople)}></div>
                <Tree />
                <div id="infobutton" className="unselectable" onClick={() => showInfo(1, treeSlice.people)} title="Click for information about the site.">i</div>
                <div id="version" onClick={() => showInfo(2, treeSlice.people)}>Version 1.0</div>
            </React.Fragment>
        ) : (
            <div>Loading...</div>
        )
    );
}

export default AppRouter;

function showInfo(tab, people) {
    function ShowInfoContent(props) {
        var [currentTab, setCurrentTab] = useState(tab);

        return (
            <React.Fragment>
                <div id="infoHeader" className="popupHeader">
                    <span className="twoTabsL" onClick={() => setCurrentTab(1)}><div>How to Use</div></span>
                    <span className="twoTabs" onClick={() => setCurrentTab(2)}><div>Version History</div></span>
                </div>

                <div id="infoDiv" className="popupDiv">
                    {currentTab === 1 && (
                        <table id="htuTable" className="tab">
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Welcome to familytree.svsantos.com!
                                        <span style={{ width: '100%', float: 'left' }}>(Better name coming soon)</span>
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ fontSize: 14 }}>
                                        <br/>
                                        Every person in the tree is a square or circle depending on gender. The lines show the relationships between each person.
                                        When you click on a person, it moves them to the center of the screen and shows you his or her parents, siblings, spouce/exes,
                                        and children.
                                        When you click on a different person, the tree will adjust to show that person's immediate family. On a computer you can also
                                        navigate by clicking and dragging on anyones square or circle (this does not work yet on tablets).
                                        <br/><br/>
                                        In the addressbar, the text after the .com has the format TreeOwner/TreeName/CurrentUser. If you manually change this, you will
                                        not be able to access the current tree you are using. However, when you click on someone, it will automatically update the current
                                        user in the address bar. So you can click on anyone you want, then bookmark the link. Now everytime you use that bookmark, the tree
                                        will center the page on that user every time.
                                        <br/><br/>
                                        The zoom buttons in the bottom left corner allow you to zoom from 50% to 200% in 10% increments.
                                        <br/><br/>
                                        When you click on a person, the View/Edit button in the bottom right changes to that person. Clicking this button allows you to view
                                        information for the current user and edit his or her information.
                                        <br/><br/>
                                        The 'i' button in the top left will show you information on the site and how to use it.
                                        <br/><br/>
                                        The current version of the website is displayed in the top right corner. Clicking on it will allow you to see the version history,
                                        as well as the new features in each version.
                                        <br/><br/>
                                    </td>
                                </tr>

                                <tr>
                                    <td>Key:</td>
                                </tr>
                                <tr>
                                    <td>
                                        <img src={keyImage} style={{ width: '100%' }} alt='' />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}

                    {currentTab === 2 && (
                        <table id="vhTable" className="tab" style={{ fontSize: 14 }}>
                            <tbody>
                                <tr>
                                    <td style={{ width: 65, fontWeight: 'bold', verticalAlign: 'top', textAlign: 'center' }}>
                                        <span style={{ width: '100%', float: 'left' }}>1.0</span>
                                        <span style={{ width: '100%', fontSize: 10, color: '#888', float: 'left' }}>May 25, 2016</span>
                                    </td>
                                    <td>
                                        <ul style={{ margin: 0, paddingLeft: 20 }}>
                                            <li>Launch of Family Tree website! Please see 'How to Use' tab for information on using this site.</li>
                                        </ul>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}
                </div>
            </React.Fragment>
        );
    }

    createDialog(<ShowInfoContent />, { width: window.innerWidth > 550 ? 500 : '90%', height: window.innerHeight > 700 ? 600 : 300 });
}

function showEditBox(tab, people, getPeople) {
    var edit = document.getElementById("editbutton");

    function getDateString(date) {
        return dateFormat(parseDate(date), 'mmmm dS, yyyy');
    }

    function getDateDropdown(type, selected) {
        selected = parseInt(selected);
        var now = new Date();
        var currentYear = now.getFullYear();

        return (
            <select type={type} style={{ marginRight: 10 }}>
                {type === 'month' && (
                    new Array(13).map((_, i) => (
                        <option key={i} value={i} selected={selected === i}>{i ? dateFormat(parseDate(`${padNum(i, 2)}/01/2024`), 'mmmm') : 'Month'}</option>
                    ))
                )}
                {type === 'day' && (
                    new Array(32).map((_, i) => (
                        <option key={i} value={i} selected={selected === i}>{i ? padNum(i, 2) : 'Day'}</option>
                    ))
                )}
                {type === 'year' && (
                    new Array(currentYear - 1800).map((_, i) => (
                        <option key={currentYear - i} value={currentYear - i} selected={selected === (currentYear - i)}>{i}</option>
                    ))
                )}
            </select>
        );
    }

    function ShowEditBoxContent(props) {
        var [currentTab, setCurrentTab] = useState(tab);

        var user = edit.attributes.edituser.value;
        if(typeof people[user.toString()] === "undefined") {
            getPeople(user);
        }

        // if(tab == 1 && $("#editTable").length == 1 && bypass !== true) {
        //     if(saveEdits(true)) {
        //         // eslint-disable-next-line no-restricted-globals
        //         var saveChanges = confirm("You have made changes to " + people[user].firstname + " " + people[user].lastname + ". Do you want to save them?");
        //         if(saveChanges) {
        //             saveEdits(false);
        //         }
        //     }
        // }

        return (
            <React.Fragment>
                <div id="infoHeader" className="popupHeader">
                    <span className="twoTabsL" onClick={() => setCurrentTab(1)}><div>View</div></span>
                    <span className="twoTabs" onClick={() => setCurrentTab(2)}><div>Edit</div></span>
                </div>

                <div id='vieweditTableDiv' class='popupDiv'>
                    <table id='viewTable' class='tab' style={{ display: (currentTab === 1 ? "" : "none") }}>
                        <tbody>
                            <tr type='name'>
                                <td style={{ width: 140 }}>Full name:</td>
                                <td>{
                                    (people[user].firstname !== null ? people[user].firstname : "")
                                    (people[user].middlename !== null ? " " + people[user].middlename : "") +
                                    (people[user].lastname !== null ? " " + people[user].lastname : "")
                                }</td>
                            </tr>

                            <tr type='sex'>
                                <td style={{ width: 140 }}>Sex:</td>
                                <td>{(people[user].sex === "M" ? "Male" : "Female")}</td>
                            </tr>

                            <tr type='bday'>
                                <td style={{ width: 140 }}>Birthday:</td>" +
                                <td>{(people[user].bday !== null ? getDateString(people[user].bday) : "")}</td>
                            </tr>
                            {people[user].death !== null && (
                                <tr type='death'>
                                    <td style={{ width: 140 }}>Death:</td>
                                    <td>{getDateString(people[user].death)}</td>
                                </tr>
                            )}

                            {people[user].birthtown !== null && (
                                <tr type='birthtown'>
                                    <td style={{ width: 140 }}>Birthtown:</td>
                                    <td>{people[user].birthtown}</td>
                                </tr>
                            )}
                            {people[user].birthcounty !== null && (
                                <tr type='birthcounty'>
                                    <td style={{ width: 140 }}>Birth County:</td>
                                    <td>{people[user].birthcounty}</td>
                                </tr>
                            )}
                            {people[user].birthstate !== null && (
                                <tr type='birthstate'>
                                    <td style={{ width: 140 }}>Birth State:</td>
                                    <td>{people[user].birthstate}</td>
                                </tr>
                            )}
                            {people[user].birthcountry !== null && (
                                <tr type='birthcountry'>
                                    <td style={{ width: 140 }}>Birth Country:</td>
                                    <td>{people[user].birthcountry}</td>
                                </tr>
                            )}

                        {/* add yearly reviews, but only able to edit current year */}

                        </tbody>
                    </table>

                    <table id='editTable' class='tab' style={{ display: (currentTab === 2 ? "" : "none") }}>
                        <tbody>

                            <tr type='firstname'>
                                <td style={{ width:140 }}>First name:</td>
                                <td><input type='text' maxlength='50' value={people[user].firstname !== null ? people[user].firstname : ""} /></td>
                                <td type='original' style={{ display: 'none' }}>{people[user].firstname !== null ? people[user].firstname : ""}</td>
                            </tr>
                            <tr type='middlename'>
                                <td style={{ width:140 }}>Middle name:</td>
                                <td><input type='text' maxlength='50' value={people[user].middlename !== null ? people[user].middlename : ""} /></td>
                                <td type='original' style={{ display: 'none' }}>{people[user].middlename !== null ? people[user].middlename : ""}</td>
                            </tr>
                            <tr type='lastname'>
                                <td style={{ width:140 }}>Last name:</td>
                                <td><input type='text' maxlength='50' value={people[user].lastname !== null ? people[user].lastname : ""} /></td>
                                <td type='original' style={{ display: 'none' }}>{people[user].lastname !== null ? people[user].lastname : ""}</td>
                            </tr>

                            <tr type='sex'>
                                <td style={{ width:140 }}>Sex:</td>
                                <td>
                                    <input type='radio' name='sex' value='M' checked={people[user].sex === "M"} /> Male
                                    <input type='radio' name='sex' value='F' checked={people[user].sex === "F"} /> Female
                                </td>
                                <td type='original' style={{ display: 'none' }}>{people[user].sex}</td>
                            </tr>

                            <tr type='bday'>
                                <td style={{ width: 140 }}>Birthday:</td>
                                <td>{
                                    getDateDropdown("month", (people[user].bday !== null ? people[user].bday.split(" / ")[0] : "0")) +
                                    getDateDropdown("day", (people[user].bday !== null ? people[user].bday.split(" / ")[1] : "0")) +
                                    getDateDropdown("year", (people[user].bday !== null ? people[user].bday.split(" / ")[2] : "0"))
                                }</td>
                                <td type='original' style={{ display: 'none' }}>
                                    <span>{people[user].bday !== null ? people[user].bday.split(" / ").join("</span><span>") : ""}</span>
                                </td>
                            </tr>
                            <tr type='death'>
                                <td style={{ width: 140 }}>Death:</td>
                                <td>{
                                    getDateDropdown("month", (people[user].death !== null ? people[user].death.split(" / ")[0] : "0")) +
                                    getDateDropdown("day", (people[user].death !== null ? people[user].death.split(" / ")[1] : "0")) +
                                    getDateDropdown("year", (people[user].death !== null ? people[user].death.split(" / ")[2] : "0"))
                                }</td>
                                <td type='original' style={{ display: 'none' }}>
                                    <span>{people[user].death !== null ? people[user].death.split(" / ").join("</span><span>") : ""}</span>
                                </td>
                            </tr>

                            <tr type='birthtown'>
                                <td style={{ width:140 }}>Birthtown:</td>
                                <td><input type='text' maxlength='100' value={people[user].birthtown !== null ? people[user].birthtown : ""} /></td>
                                <td type='original' style={{ display: 'none' }}>{people[user].birthtown !== null ? people[user].birthtown : ""}</td>
                            </tr>
                            <tr type='birthcounty'>
                                <td style={{ width:140 }}>Birth County:</td>
                                <td><input type='text' maxlength='100' value={people[user].birthcounty !== null ? people[user].birthcounty : ""} /></td>
                                <td type='original' style={{ display: 'none' }}>{people[user].birthcounty !== null ? people[user].birthcounty : ""}</td>
                            </tr>
                            <tr type='birthstate'>
                                <td style={{ width:140 }}>Birth State:</td>
                                <td><input type='text' maxlength='100' value={people[user].birthstate !== null ? people[user].birthstate : ""} /></td>
                                <td type='original' style={{ display: 'none' }}>{people[user].birthstate !== null ? people[user].birthstate : ""}</td>
                            </tr>
                            <tr type='birthcountry'>
                                <td style={{ width:140 }}>Birth Country:</td>
                                <td><input type='text' maxlength='100' value={people[user].birthcountry !== null ? people[user].birthcountry : ""} /></td>
                                <td type='original' style={{ display: 'none' }}>{people[user].birthcountry !== null ? people[user].birthcountry : ""}</td>
                            </tr>

                            {/* // add yearly reviews, but only able to edit current year */}

                            <tr id='password' style={{ display: 'none' }}>
                                <td style={{ width: 140 }}>Enter Password: </td>
                                <td><input type='password' maxlength='20'/></td>
                            </tr>
                            <tr>
                                <td colspan='2'><input type='button' value='Save Changes' onclick='saveEdits(false)' style={{ float: 'right', marginTop: 10 }} /></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }

    createDialog(<ShowEditBoxContent />, { width: 500, height: window.innerHeight > 700 ? 600 : 300 });
}
