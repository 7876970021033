import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppLayoutContext } from './appLayout/appLayout.js';
import { LoginContext } from './loginProvider/loginProvider.js';
export function useCloseOnEscape(close) {
  var closeOnEscape = event => {
    if (event.key === 'Escape') {
      console.log('closing from key press');
      close();
    }
  };

  useEffect(() => {
    console.log('esc start');
    window.addEventListener('keyup', closeOnEscape);
    return () => console.log('esc end') || window.removeEventListener('keyup', closeOnEscape);
  }, []);
}
export function useAppLayout() {
  var appLayout = useContext(AppLayoutContext);
  return appLayout;
}
export function useLogin() {
  var login = useContext(LoginContext);
  return login;
}
export function useEnterKey(func) {
  var onEnter = event => {
    if (event.key === 'Enter') {
      func(event);
    }
  };

  return onEnter;
}
export function useNavigateCmdClick(path) {
  var navigate = useNavigate();
  return function (event) {
    if (event.metaKey || event.ctrlKey) {
      window.open(path, '_blank');
    } else {
      navigate(path);
    }
  };
}
;