import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { GlobalStateProvider } from './utils/reactUtils.js';
import TreeLayout from './components/treeLayout.js';

import './index.scss';
import './style.css';

import './scripts/main-script.js';
import './scripts/createtree.js';
import './scripts/edit.js';

var pathVars = window.location.pathname.split('/').filter((item) => !!item);
var user = parseInt(pathVars[0]) || 0;
var treeName = pathVars[1];
var currentUser = parseInt(pathVars[2]) || 0;
// var viewid = 0; //<?=$id?>;
if (!user) { user = 85; }
if (!treeName) { treeName = 'gagovieirafamily'; }
if (!currentUser) { currentUser = user; }
//alert(navigator.userAgent);

document.title = 'Family Tree - ' + treeName;

// var root = ReactDOM.createRoot(document.getElementById('tree'));
// root.render(
//     <React.StrictMode>
//         <Tree user={user} treeName={treeName} startingUser={currentUser} />
//     </React.StrictMode>
// );

var root = ReactDOM.createRoot(document.querySelector('.newMain'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <GlobalStateProvider>
                <TreeLayout />
            </GlobalStateProvider>
        </BrowserRouter>
    </React.StrictMode>
);
