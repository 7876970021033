import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';
import dateFormat from 'dateformat';

import { useStateSlice } from '../utils/reactUtils.js';

import './person.scss';
import './line.scss';

var formatDate = (date) => date ? dateFormat(date, 'mmm d, yyyy') : '';
var horizontalSeperation = 130;
var verticalSeperation = 160;

var siblingLineHeight = 65;

function Person(props) {
    var { id, relativeToId } = props;

    var navigate = useNavigate();
    var urlParams = useParams();

    var user = urlParams.homePath;
    var treeName = urlParams.homePath2;
    // var currentUser = parseInt(urlParams.homePath3);

    var [treeSlice] = useStateSlice('tree');
    var { people } = treeSlice;

    var self = people[id];
    var { birthday, death, gender, name } = self;

    var mainPerson = people[relativeToId];

    var mainLeftSpouse = null;
    if ((mainPerson.spouse && mainPerson.exes?.length) || mainPerson.exes?.length > 1) {
        mainLeftSpouse = (mainPerson.spouse ? people[mainPerson.exes[0]] : people[mainPerson.exes[1]]);
    }
    var siblingsLeftBuffer = mainLeftSpouse ? horizontalSeperation : 0;
    var mainLeftSpouseChildren = mainPerson.children.filter((c) => mainLeftSpouse?.children.includes(c));

    var mainRightSpouse = (mainPerson.spouse || mainPerson.exes?.length) ? people[mainPerson.spouse || mainPerson.exes[0]] : null;
    var siblingsRightBuffer = mainRightSpouse ? horizontalSeperation : 0;
    var mainRightSpouseChildren = mainPerson.children.filter((c) => mainRightSpouse?.children.includes(c));

    if (mainRightSpouseChildren.length && mainLeftSpouseChildren.length) {
        siblingsLeftBuffer += ((mainLeftSpouseChildren.length - 1) * horizontalSeperation);
        siblingsRightBuffer += ((mainRightSpouseChildren.length - 1) * horizontalSeperation);
    }

    var lines = [];
    var top = 0;
    var left = 0;
    if (self.id !== relativeToId) {
        if (self.id === mainPerson.spouse) {
            left += siblingsRightBuffer;
        } else if (mainPerson.exes.includes(self.id)) {
            if (mainPerson.exes[0] === self.id && !mainPerson.spouse) {
                left += siblingsRightBuffer;
                if (siblingsRightBuffer > horizontalSeperation) {
                    left -= horizontalSeperation / 2;
                }
            } else {
                left -= siblingsLeftBuffer;
                if (siblingsLeftBuffer > horizontalSeperation) {
                    left += horizontalSeperation / 2;
                }
            }
        } else if (mainPerson.siblings.includes(self.id)) {
            let olderSiblings = mainPerson.siblings.filter(
                (s) => people[s].birthday < mainPerson.birthday ||
                (people[s].birthday.getTime() === mainPerson.birthday.getTime() && people[s].name.localeCompare(mainPerson.name) === -1)
            ).reverse();
            let youngerSiblings = mainPerson.siblings.filter((s) => !olderSiblings.includes(s));
            if (olderSiblings.includes(self.id)) {
                left -= siblingsLeftBuffer + horizontalSeperation + (horizontalSeperation * olderSiblings.indexOf(self.id));
            } else if (youngerSiblings.includes(self.id)) {
                left += siblingsRightBuffer + horizontalSeperation + (horizontalSeperation * youngerSiblings.indexOf(self.id));
            }
        } else if (mainPerson.children.includes(self.id)) {
            let otherParent = self.mother === mainPerson.id ? people[self.father] : people[self.mother];
            if (!otherParent) {
                // unknown other parent
                otherParent = { ...mainPerson };
            }
            var sharedChildren = mainPerson.children.filter((c) => otherParent.children.includes(c));
            var selfPosition = sharedChildren.indexOf(self.id);

            left += horizontalSeperation * selfPosition;
            left -= horizontalSeperation * ((sharedChildren.length - 1) / 2);
            if (otherParent.id === mainPerson.spouse || (!mainPerson.spouse && otherParent.id === mainPerson.exes[0])) {
                // if spouse or first ex, shift right
                left += siblingsRightBuffer / 2;
            } else {
                // if first ex with spouse or second ex, shift left
                left -= siblingsLeftBuffer / 2;
            }

            top += verticalSeperation;
        } else if (self.id === mainPerson.father) {
            if (mainPerson.mother) {
                left -= horizontalSeperation / 2;
            }
            top -= verticalSeperation;
        } else if (self.id === mainPerson.mother) {
            if (mainPerson.father) {
                left += horizontalSeperation / 2;
            }
            top -= verticalSeperation;
        }

        if (mainPerson.siblings.includes(self.id)) {
            lines.push(<VLine key={`sibling${self.id}`} name={`sibling${self.id}`} left={left} top={top - siblingLineHeight} length={siblingLineHeight} />);
        }
        if (mainPerson.children.includes(self.id)) {
            lines.push(<VLine key={`child${self.id}`} name={`child${self.id}`} left={left} top={top - siblingLineHeight} length={siblingLineHeight} />);
        }
    } else {
        if (self.spouse) {
            let lineLength = siblingsRightBuffer;
            if (siblingsRightBuffer > horizontalSeperation) {
                lineLength -= horizontalSeperation / 2;
            }
            lines.push(<HLine key={`spouse${self.id}`} name={`spouse${self.id}`} left={left} top={top} length={lineLength} />);

            if (self.exes.length) {
                let lineLength = siblingsLeftBuffer;
                if (siblingsLeftBuffer > horizontalSeperation) {
                    lineLength -= horizontalSeperation / 2;
                }
                lines.push(<HLine key={`ex${self.id}`} name={`ex${self.id}`} displayType='dotted' left={left - lineLength} top={top} length={lineLength} />);
            }
        } else {
            if (self.exes.length) {
                let lineLength = siblingsRightBuffer;
                if (siblingsRightBuffer > horizontalSeperation) {
                    lineLength -= horizontalSeperation / 2;
                }
                lines.push(<HLine key={`ex1_${self.id}`} name={`ex1_${self.id}`} displayType='dotted' left={left} top={top} length={lineLength} />);
            }
            if (self.exes.length > 1) {
                let lineLength = siblingsLeftBuffer;
                if (siblingsLeftBuffer > horizontalSeperation) {
                    lineLength -= horizontalSeperation / 2;
                }
                lines.push(<HLine key={`ex2_${self.id}`} name={`ex2_${self.id}`} displayType='dotted' left={left - lineLength} top={top} length={lineLength} />);
            }
        }

        if (self.mother && self.father) {
            lines.push(<HLine key={`parentsHor${self.id}`} name={`parentsHor${self.id}`} left={left - (horizontalSeperation / 2)} top={top - verticalSeperation} length={horizontalSeperation} />);
        }
        if (self.mother || self.father) {
            lines.push(<VLine key={`parents${self.id}`} name={`parents${self.id}`} left={left} top={top - verticalSeperation} length={verticalSeperation} />);
        }

        if (self.children.length) {
            var childrenGroups = {};
            for (var childId of self.children) {
                var child = people[childId];
                let otherParent = child.mother === self.id ? child.father : child.mother;
                if (!childrenGroups[otherParent]) {
                    childrenGroups[otherParent] = [childId];
                } else {
                    childrenGroups[otherParent].push(childId);
                }
            }

            for (let [otherParent, children] of Object.entries(childrenGroups)) {
                otherParent = parseInt(otherParent);
                var offset = ((otherParent === self.spouse || (!self.spouse && otherParent === self.exes[0])) ? siblingsRightBuffer : -siblingsLeftBuffer) / 2;
                lines.push(<VLine key={`children${otherParent}`} name={`children${otherParent}`} left={left + offset} top={top} length={verticalSeperation - siblingLineHeight} />);

                if (children.length > 1) {
                    lines.push((
                        <HLine
                            key={`childrenHor${otherParent}`}
                            name={`childrenHor${otherParent}`}
                            left={left - (horizontalSeperation * ((children.length - 1) / 2)) + offset}
                            top={top + verticalSeperation - siblingLineHeight}
                            length={horizontalSeperation * (children.length - 1)}
                        />
                    ));
                }
            }
        }

        if (mainPerson.siblings.length) {
            let olderSiblings = mainPerson.siblings.filter(
                (s) => people[s].birthday < mainPerson.birthday ||
                (people[s].birthday.getTime() === mainPerson.birthday.getTime() && people[s].name.localeCompare(mainPerson.name) === -1)
            ).reverse();
            let youngerSiblings = mainPerson.siblings.filter((s) => !olderSiblings.includes(s));

            var siblingsLineLeft = left - (horizontalSeperation * olderSiblings.length);
            if (olderSiblings.length) {
                siblingsLineLeft -= siblingsLeftBuffer;
            }
            var maxRight = left + (horizontalSeperation * youngerSiblings.length);
            if (youngerSiblings.length) {
                maxRight += siblingsRightBuffer;
            }
            lines.push((
                <HLine
                    key={`siblingsHor${self.id}`}
                    name={`siblingsHor${self.id}`}
                    left={siblingsLineLeft}
                    top={top - siblingLineHeight}
                    length={maxRight - siblingsLineLeft}
                />
            ));
        }
    }

    return (
        <React.Fragment>
            <div
                className={classNames('person', gender)}
                name={`person${self.id}`}
                onClick={() => navigate(`/${user}/${treeName}/${self.id}`)}
                style={{ top, left }}
            >
                <span className='personName'>
                    <p>{name}</p>
                </span>
                <span className='personDates'>
                    <p>{birthday && `b. ${formatDate(birthday)}`}<br />{death && `d. ${formatDate(death)}`}</p>
                    <br />
                </span>
            </div>
            {lines}
        </React.Fragment>
    );
}

function VLine(props) {
    return <Line {...props} type='vertical' />;
}

function HLine(props) {
    return <Line {...props} type='horizontal' />;
}

function Line(props) {
    var { displayType = 'solid', left = 0, length = 0, name, top = 0, type = 'vertical' } = props;

    var lengthType = type === 'vertical' ? 'height' : 'width';

    return (
        (length || null) && <div className={classNames('line', displayType)} name={name} style={{ top, left, [lengthType]: length }} />
    );
}

export default Person;
