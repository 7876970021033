import React from 'react';
import { useParams } from 'react-router-dom';
// import dateFormat from 'dateformat';

// import { ajax, padNum, parseDate } from 'svs-utils/web';
// import { createAlert, createDialog } from 'svs-utils/react';

import { useStateSlice } from '../utils/reactUtils.js';
import Person from './person.js';

import './tree.scss';

function Tree(props) {
    var [treeSlice] = useStateSlice('tree');
    var { people } = treeSlice;

    var urlParams = useParams();

    // var user = urlParams.homePath;
    // var treeName = urlParams.homePath2;
    var currentUser = parseInt(urlParams.homePath3);

    var person = people[currentUser];
    var peopleToDraw = [person.father, person.mother, currentUser, person.spouse, ...person.exes, ...person.siblings, ...person.children].filter((id) => !!id);

    return (
        <div id='tree'>
            {peopleToDraw.map((id) => <Person key={id} id={id} relativeToId={currentUser} />)}
        </div>
    );
}

export default Tree;