import React from 'react';

import { getGlobalStateUtils } from 'svs-utils/react';

var initialGlobalState = {
    tree: {
        people: {},
    },
};
export var { GlobalStateProvider, useStateSlice } = getGlobalStateUtils(React, initialGlobalState);
