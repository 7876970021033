import dateFormat from 'dateformat';
import chalk from 'chalk';

export var dummyLog = () => {};

export function sleep(ms) {
    return new Promise((resolve) => {
        setTimeout(resolve, ms);
    });
}

export function sleepSec(seconds = 1) {
    return new Promise((resolve) => {
        setTimeout(resolve, seconds * 1000);
    });
}

export function sleepMin(minutes = 1) {
    return new Promise((resolve) => {
        setTimeout(resolve, minutes * 60 * 1000);
    });
}

export function sleepTilNextMinute(date = null) {
    return new Promise((resolve) => {
        var now = date || new Date();
        var secondsToWait = Math.max(60 - now.getSeconds(), 0);
        setTimeout(resolve, secondsToWait * 1000);
    });
}
export function sleepTilNextHour(date = null) {
    return new Promise((resolve) => {
        var now = date || new Date();
        var currentSeconds = (now.getMinutes() * 60) + now.getSeconds();
        var secondsToWait = Math.max((60 * 60) - currentSeconds, 0);
        setTimeout(resolve, secondsToWait * 1000);
    });
}

export function sleepDummy() {
    // return Promise.resolve(); // this is not really asynchronous
    return new Promise((resolve) => setTimeout(resolve, 1)); // this is
    // not actually sure which is better for testing. The non-asynchronous one would hold all of the error messages till the end
}

export function round(number, numberOfDecimals = 0) {
    return Math.round((number + Number.EPSILON) * Math.pow(10, numberOfDecimals)) / Math.pow(10, numberOfDecimals)
}

export function padNum(number, targetLength = 2) {
    return number.toString().padStart(targetLength, 0);
}

export function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

export function dateToJson() {
    try {
        return dateFormat(this, `yyyy-mm-dd HH:MM:ss.lp`);
    } catch (error) {
        console.log('HEY OVER HERE!! DATE.toJSON error - ', this, error.message, error.stack);
        return 'Date.toJSON error: ' + error.message;
    }
}

export function throttle(func, limit) {
    var lastFunc;
    var lastRan;
    return (...args) => {
        if (!lastRan) {
            func(...args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(
                () => {
                    if ((Date.now() - lastRan) >= limit) {
                        func(...args);
                        lastRan = Date.now();
                    }
                },
                limit - (Date.now() - lastRan),
            );
        }
    }
}

export function randomInt(min = 0, max = 100) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

export function randomString(length = 10, chars = 'a#') {
    var mask = '';
    if (chars.includes('a')) {
        mask += 'abcdefghijklmnopqrstuvwxyz';
    }
    if (chars.includes('A')) {
        mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    }
    if (chars.includes('#')) {
        mask += '0123456789';
    }
    if (chars.includes('!')) {
        mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
    }
    var result = '';
    for (var i = 0; i < length; i++) {
        result += mask[Math.floor(Math.random() * mask.length)];
    }
    return result;
}

export function capsFirst(string) {
    return string[0].toUpperCase() + string.substring(1);
}

export function padString(string, targetLength, fill = ' ', align = 'left') {
    if (align === 'left') {
        return string.padEnd(targetLength, fill);
    } else if (align === 'center') {
        return string.padStart(string.length + Math.floor((targetLength - string.length) / 2), fill).padEnd(targetLength, fill);
    } else { // right
        return string.padStart(targetLength, fill);
    }
}

export function trimString(...stringParts) {
    // stringParts[0] is array of actual strings, the rest of the array is the ${} fill values
    var string = '';
    for (var [i, stringPart] of stringParts[0].entries()) {
        string += (i === 0 ? '' : stringParts[i]) + stringPart;
    }
    string = string.replace(/\n\s+/g, '\n').trim();
    return string;
}

export function trimSpaces(...stringParts) {
    // stringParts[0] is array of actual strings, the rest of the array is the ${} fill values
    var string = '';
    for (var [i, stringPart] of stringParts[0].entries()) {
        string += (i === 0 ? '' : stringParts[i]) + stringPart;
    }
    string = string.replace(/\n +/g, '\n').trim();
    return string;
}

export function numberWithCommas(number, decimalPlaces = null) {
    if (decimalPlaces == null) {
        decimalPlaces = countDecimals(number);
    }
    // return number.toFixed(decimalPlaces).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    var parts = number.toFixed(decimalPlaces).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

export function countDecimals(number) {
    if (Math.floor(number.valueOf()) === number.valueOf()) {
        return 0;
    }

    var str = number.toString();
    // if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
    //     return str.split('-')[1] || 0;
    // } else
    if (str.indexOf('.') !== -1) {
        return str.split('.')[1].length || 0;
    }
    return str.split('-')[1] || 0;
}

export function printTable(table) {
    var columnLengths = [];
    for (var [r, row] of table.entries()) {
        for (var [c, column] of row.entries()) {
            var value = null;
            if (typeof column === 'object') {
                if (column.format === 'money') {
                    value = '$ ' + numberWithCommas(column.value, 2);
                } else {
                    value = column.value.toString();
                }
            } else {
                value = column.toString();
            }
            columnLengths[c] = Math.max(columnLengths[c] || 0, value.length);
        }
    }

    var noOp = (arg) => arg;
    var output = '';
    for (var [r, row] of table.entries()) {
        if (r === 0) {
            output += '┌─' + row.map((value, i) => padString('', columnLengths[i], '─')).join('─┬─') + '─┐\n';
        }

        output += '│ ';
        for (var [c, column] of row.entries()) {
            if (c > 0) {
                output += ' │ ';
            }
            if (typeof column === 'object') {
                if (r === 0) {
                    column.align = column.align || 'center';
                }
                var color = (column.color && chalk[column.color]) || noOp;
                if (column.format === 'money') {
                    output += color('$' + padString(numberWithCommas(column.value, 2), columnLengths[c] - 1, ' ', 'right'));
                } else {
                    output += color(padString(column.value.toString(), columnLengths[c], ' ', column.align));
                }
            } else {
                var align = 'left';
                if (r === 0) {
                    align = 'center';
                }
                output += padString(column.toString(), columnLengths[c], ' ', align);
            }
        }
        output += ' │\n';

        if (r === 0) {
            output += '├─' + row.map((value, i) => padString('', columnLengths[i], '─')).join('─┼─') + '─┤\n';
        }
    }
    output += '└─' + table[0].map((value, i) => padString('', columnLengths[i], '─')).join('─┴─') + '─┘';

    console.log(output);
}

export function parseJson(objString) {
    if (typeof objString === 'string') {
        try {
            var object = JSON.parse(objString);
            return object;
        } catch (error) {
            return objString;
        }
    } else {
        return objString;
    }
}

export function parseDate(dateString, assumeUtc = true) {
    var date = null;
    if (!dateString) {
        return false;
    } else if (dateString instanceof Date) {
        date = dateString;
    } else if (typeof dateString === 'number' || /^\d+$/.test(dateString)) {
        dateString = parseInt(dateString);
        if (dateString < 5000000000) { // must be in seconds, convert to milliseconds
            // this is 5 billion
            // if in seconds, 5 billion would be (Fri Jun 11 2128 04:53:20 GMT-0400), so if greater than that, has to be milliseconds (fine to parse)
            // if in milliseconds, 5 billion would be (Fri Feb 27 1970 15:53:20 GMT-0500), and I'm probably not dealing with integer dates that early, so must be seconds (mul by 1000 to parse)
            // if need to work with dates < 1971 or > 2128, then I just need to be careful with those
            dateString *= 1000;
        } else if (dateString > 1000000000000000000n) { // must be in nano-seconds, convert to milliseconds
            dateString /= 1000000;
        } else if (dateString > 1000000000000000) { // must be in micro-seconds, convert to milliseconds
            dateString /= 1000;
        }

        if (dateString < 100000000000) {
            // this is 100 billion milliseconds, or 100 million seconds, which would be (Sat Mar 03 1973 04:46:40 GMT-0500)
            // so probably not dealing with integer times that small, must just be a regular number
            return false;
        }

        date = new Date(dateString);
    } else if (/^\d{4}-\d{2}$/.test(dateString)) { // "2022-09" // month format - assume 1st of month, local time
        date = new Date(dateString + '-01T00:00:00');
    } else if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) { // "2022-07-13" // assume local time
        date = new Date(dateString + 'T00:00:00');
    } else if (/^\d{2}\/\d{2}\/\d{4}$/.test(dateString)) { // "08/11/2022" // assume local time
        date = new Date(dateString + ' 00:00:00');
    } else if (/^\d{2}\/\d{2}\/\d{4}\s\d{2}:\d{2}(:\d{2}(.\d{1,12})?)?Z?$/.test(dateString)) { // "06/21/2022 17:08:34.123Z" or "06/21/2022 17:08Z"
        if (!dateString.endsWith('Z') && assumeUtc) {
            dateString += 'Z';
        }
        date = new Date(dateString);
    } else if (/^\d{4}-\d{2}-\d{2}(T|\s)\d{2}:\d{2}:\d{2}(.\d{1,12})?Z?$/.test(dateString)) { // 2022-05-31T17:08:34.123Z
        if (!dateString.endsWith('Z') && assumeUtc) {
            dateString += 'Z';
        }
        date = new Date(dateString.replace(' ', 'T'));
    } else if (/^\d{4}:\d{2}:\d{2}(\s)\d{2}:\d{2}:\d{2}(.\d{1,12})?$/.test(dateString)) { // "2023:05:23 11:29:19"
        dateString = dateString.split(' ');
        dateString[0] = dateString[0].replace(/:/g, '-');
        dateString = dateString.join('T');
        if (!dateString.endsWith('Z') && assumeUtc) {
            dateString += 'Z';
        }
        date = new Date(dateString);
    } else if (/^\d{4}:\d{2}:\d{2}(\s)\d{2}:\d{2}:\d{2}(.\d{1,12})?(\+|-)\d{2}:?\d{2}$/.test(dateString)) { // "2023:06:07 14:34:29.568-04:00"
        dateString = dateString.split(' ');
        dateString[0] = dateString[0].replace(/:/g, '-');
        dateString = dateString.join('T');
        date = new Date(dateString);
    } else if (/^\d{4}-\d{2}-\d{2}(T|\s)\d{2}:\d{2}$/.test(dateString)) { // "2022-05-31 17:08" // assume local time
        date = new Date(dateString.replace(' ', 'T') + ':00');
    } else if (/^\d{4}-\d{2}-\d{2}(T|\s)\d{2}:\d{2}:\d{2}(.\d{1,12})?(\+|-)\d{2}:?\d{2}$/.test(dateString)) { // 2022-05-31T17:08:34.123+01:00
        date = new Date(dateString.replace(' ', 'T'));
    } else if (/^\d{4}-\d{2}-\d{2}(T|\s)\d{2}:\d{2}:\d{2}(.\d{1,12})?(\+|-)\d{2}$/.test(dateString)) { // 2023-10-05 12:06:17.123-04
        date = new Date(dateString.replace(' ', 'T') + ':00');
    } else if (/^\d{2}:\d{2}:\d{2} [A-Z]{3} \d{2}-\d{2}-\d{4}$/.test(dateString)) { // "19:59:59 EDT 08-19-2022"
        date = new Date(dateString);
    } else if (/^[a-zA-Z]{3} \d{2},? \d{4}$/.test(dateString)) { // "Jan 02, 2023" // assume local time
        date = new Date(dateString + ' 00:00:00');
    } else if (/^\d{2}:\d{2}(:\d{2})?$/.test(dateString)) { // "14:00" or "14:00:00" // assume today
        date = new Date();
        var timeParts = dateString.split(':');
        date.setHours(parseInt(timeParts[0]), parseInt(timeParts[1]), parseInt(timeParts[2] || '0'));
    } else if (/^[a-zA-Z]{3},? [a-zA-Z]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2}$/.test(dateString)) { // "Sun, Jan 29 2023 12:32:14" // assume local time
        date = new Date(dateString);
    } else if (/^[a-zA-Z]{3},? [a-zA-Z]{3} \d{2} \d{4} \d{1,2}:\d{2}(:\d{2})? [aApP][mM]$/.test(dateString)) { // "Sun, Jan 29 2023 12:32:14 AM" // assume local time
        date = new Date(dateString);
    } else if (dateString) {
        return false;
    }

    return date;
}

export function calculateTimeSpanString(start, end) {
    start = new Date(start.getTime());
    end = new Date(end.getTime());

    var spans = [
        { label: 'year', value: 1000 * 60 * 60 * 24 * 365, add: (d) => d.setFullYear(d.getFullYear() + 1) },
        { label: 'month', value: 1000 * 60 * 60 * 24 * 365 / 12, add: (d) => d.setMonth(d.getMonth() + 1) },
        { label: 'day', value: 1000 * 60 * 60 * 24, add: (d) => d.setDate(d.getDate() + 1) },
    ];
    var timespanString = '';
    for (var span of spans) {
        var number = 0;

        while (true) {
            var newStart = new Date(start.getTime());
            span.add(newStart);
            if (newStart <= end) {
                number++;
                start = newStart;
            } else {
                break;
            }
        }

        if (number > 0) {
            timespanString += `${number} ${span.label}${number === 1 ? '' : 's'} `;
        }
    }

    return timespanString.trim();
}

// for use with JSON.stringifySafe(object, jsonReplacer)
export function getJsonReplacer(numberOfDecimals = 4) {
    // formats number to number of decimal places
    // defaulting to 4 cause some prices have more than 2 decimal places
    return (key, value) => {
        if (typeof value === 'number') {
            return Number(value.toFixed(numberOfDecimals));
        } else if (value instanceof Date) {
            // just a catch all, won't really be used since I just overridded the Date.prototype.toJSON() function
            try {
                return dateFormat(value, `yyyy-mm-dd HH:MM:ss.lp`);
            } catch (error) {
                return 'jsonReplacer error: ' + error.message;
            }
        } else {
            return value;
        }
    };
}

function makeQueryString(data) {
    var keys = Object.keys(data);
    if (!keys.length) {
        return '';
    }

    keys.sort((a, b) => a.localeCompare(b));
    var queryString = '?' + keys.map((key) => `${key}=${data[key]}`).join('&');
    return queryString;
}

var ajaxCalls = {};
export async function ajax(options) {
    var { allowConcurrent = false, domain = null, data = {}, filesData = null, endPoint } = options;

    var queryString = makeQueryString(data);
    var ajaxEndPoint = endPoint + queryString;
    if (!ajaxCalls[ajaxEndPoint]) {
        ajaxCalls[ajaxEndPoint] = {};
    }
    if (!allowConcurrent && ajaxCalls[ajaxEndPoint].running) {
        while (ajaxCalls[ajaxEndPoint].running) {
            await sleep(10);
        }
        if (ajaxCalls[ajaxEndPoint].results) {
            return ajaxCalls[ajaxEndPoint].results;
        }
    }

    domain = domain || window.location.host;
    console.log('domain', domain);
    if (domain.endsWith(':2999')) {
        if (/^[a-z]+\.dev\.svsantos\.com:2999$/.test(domain) || domain.startsWith('dev.') || domain.endsWith('.buffalo-lake.ts.net:2999')) {
            domain = domain.replace(':2999', ':3000');
        } else if (domain === 'localhost:2999') {
            domain = domain.replace(':2999', ':3000');
        }
    } else if (domain.endsWith(':2901')) {
        domain = domain.replace(':2901', ':2900');
    }

    var url = `${window.location.protocol}//${domain}/api${endPoint}`;
    var fetchOptions = { credentials: 'same-origin' };
    if (process.env.NODE_ENV === 'development') {
        fetchOptions.credentials = 'include';
    }

    var headers = { 'Content-Type': 'application/json; charset=UTF-8' };
    if (filesData) {
        headers = {};
        if (queryString) {
            url += queryString;
        }
    }

    ajaxCalls[ajaxEndPoint].running = true;

    try {
        var response = await fetch(url, {
            method: 'POST',
            headers,
            redirect: 'follow',
            ...fetchOptions,
            body: filesData || JSON.stringify(data),
        });
        var results = await response.json();
        results.responseObject = response;
    } catch (error) {
        ajaxCalls[ajaxEndPoint].running = false;
        ajaxCalls[ajaxEndPoint].results = null;
        throw error;
    }

    ajaxCalls[ajaxEndPoint].running = false;
    ajaxCalls[ajaxEndPoint].results = results;

    return results;
}

export function getQueryParams() {
    return Object.fromEntries(new URLSearchParams(window.location.search).entries());
}

export function getLocalCeiling(number) {
    var original = number;
    var count = 0;
    while (Math.floor(number) >= 10) {
        number = number / 10;
        count++;
    }

    var magnitude = Math.pow(10, count - 1);
    var localCeil = Math.ceil(original / magnitude) * magnitude;

    return localCeil;
}

export function getLocalFloor(number) {
    var original = number;
    var count = 0;
    var limit = original < 100 ? 1 : 10;
    number = Math.abs(number);
    while (Math.floor(number) >= limit) {
        number = number / 10;
        count++;
    }

    var magnitude = Math.pow(10, count - 1);
    var localFloor = Math.floor(original / magnitude) * magnitude;

    return localFloor;
}

export function formatError(error) {
    if (error instanceof Error) {
        if (error.message) {
            Object.defineProperty(error, 'message', { enumerable: true });
        }
        if (error.stack) {
            Object.defineProperty(error, 'stack', { enumerable: true });
        }
    }
    return error;
}

export function isIterable(input) {
    if (input === null || input === undefined) {
        return false;
    }

    return typeof input[Symbol.iterator] === 'function';
}

export function arraysEqual(a, b) {
    if (a === b) {
        return true;
    }
    if (a == null || b == null) {
        return false;
    }
    if (a.length !== b.length) {
        return false;
    }

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) {
            return false;
        }
    }

    return true;
}
